<template>
  <div class="clock">
    <div class="face">
      <div class="hand hour" :style="{ transform: `rotate(${hoursDegree}deg)` }"></div>
      <div class="hand minute" :style="{ transform: `rotate(${minutesDegree}deg)` }"></div>
      <div class="hand second" :style="{ transform: `rotate(${secondsDegree}deg)` }"></div>
      <div class="cover"></div>
      <div class="second-bulb"></div>
      <div class="number twelve">12</div>
      <div class="number three">3</div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        hoursDegree: 0,
        minutesDegree: 0,
        secondsDegree: 0,
      }
    },
    mounted() {
      this.startClock()
    },
    methods: {
      startClock() {
        setInterval(() => {
          const now = new Date()
          const seconds = now.getSeconds()
          const minutes = now.getMinutes()
          const hours = now.getHours()

          this.secondsDegree = (seconds / 60) * 360
          this.minutesDegree = (minutes / 60) * 360 + (seconds / 60) * 6
          this.hoursDegree = ((hours % 12) / 12) * 360 + (minutes / 60) * 30
        }, 1000)
      },
    },
  }
</script>

<style scoped>
  .clock {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #e4e2e2;
    box-shadow: 1.5rem 3rem 2rem rgba(144, 135, 127, 0.15), 0.5rem 1rem 1.5rem rgba(104, 93, 82, 0.8);
  }

  .face {
    position: relative;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: white;
    top: 5%;
    left: 5%;
    box-shadow: inset 0 0 0.4rem rgba(104, 93, 82, 0.5), inset 0.2rem 0.1rem 0.4rem rgba(144, 135, 127, 0.4);
  }

  .hand {
    position: absolute;
    width: 4%;
    left: 48%;
    background: #928779;
    border-radius: 2px;
    transform-origin: bottom;
  }

  .hour {
    height: 35%;
    top: 15%;
  }

  .minute {
    height: 45%;
    top: 5%;
  }

  .second {
    height: 48%;
    background: #c15c48;
    top: 2%;
  }

  .cover {
    position: absolute;
    width: 10%;
    height: 10%;
    background: #917465;
    border-radius: 50%;
    top: 45%;
    left: 45%;
  }

  .second-bulb {
    width: 4%;
    height: 4%;
    background: #c15c48;
    border-radius: 50%;
    position: absolute;
    top: 48%;
    left: 48%;
    transform: translate(-50%, -50%);
  }

  .number.twelve {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.2rem;
    font-weight: bold;
    color: #685d52;
  }

  .number.three {
    position: absolute;
    top: 50%;
    left: 85%;
    transform: translateY(-50%);
    font-size: 1.2rem;
    font-weight: bold;
    color: #685d52;
  }

  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .clock {
      width: 120px;
      height: 120px;
    }
  }

  @media (max-width: 400px) {
    .clock {
      width: 100px;
      height: 100px;
    }
  }
</style>
