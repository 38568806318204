<template>
    <div class="create-edit-role">
        <h1>{{ title }}</h1>
        <p>{{ description }}</p>
    </div>
</template>

<script>
export default {
    name: 'CreateEditRole',
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.create-edit-role {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
</style>