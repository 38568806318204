<template>
    <div class="create-edit-user">
        <h1>{{ userName }}</h1>
        <p>{{ userEmail }}</p>
    </div>
</template>

<script>
export default {
    name: 'CreateEditUser',
    data() {
        return {
            userName: 'Anny Zahid',
            userEmail: 'anny.zahid@example.com'
        };
    }
};
</script>

<style scoped>
.create-edit-user {
    font-family: Arial, sans-serif;
    text-align: center;
}
</style>