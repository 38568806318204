<template>
  <div class="create-edit-infoitem">
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="custom-toolbar-title">
        {{ 'Create Event or News Post' }}
      </v-toolbar-title>
    </v-toolbar>
    <Loader :loading="showLoader" />
    <div class="create-edit-info-item">
      <v-container fluid>
        <v-form ref="form" lazy-validation>
          <!-- Title -->
          <v-text-field v-model="title" :rules="[rules.required, rules.titleLimit]" label="Title"
            prepend-inner-icon="mdi-format-title" variant="underlined" color="primary" counter="100"
            @change="isTitleChanged = true" required></v-text-field>

          <!-- Category Dropdown -->
          <v-select v-model="category" :items="categories" :disabled="isEdit" label="Category"
            prepend-inner-icon="mdi-tag" variant="underlined" color="primary" @change="categoryChanged === true"
            required></v-select>

          <!-- Event Fields -->
          <template v-if="category === 'Event'">
            <!-- Event Date Picker -->
            <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
              min-width="290px">
              <template v-slot:activator="{ props }">
                <v-text-field v-bind="props" v-model="eventDateText" label="Event Date"
                  prepend-inner-icon="mdi-calendar" variant="underlined" color="primary" readonly required
                  @change="isEventDateChanged = true"></v-text-field>
              </template>
              <v-date-picker color="primary" max-width="250px" max-height="350px" min-width="250px" elevation="3"
                rounded="lg" hide-header show-adjacent-months v-model="eventDatePickerModel"
                @update:modelValue="updateEventDate"></v-date-picker>
            </v-menu>

            <!-- Short Text Content for Event -->
            <v-textarea v-model="shortTextContent" :rules="[rules.required, rules.lineLimit]" label="Short Text Content"
              prepend-inner-icon="mdi-text" variant="underlined" color="primary" rows="1"
              @change="isShortTextContentChanged = true" counter="250" required></v-textarea>
          </template>

          <!-- News and Announcement Fields -->
          <template v-if="category === 'News' || category === 'Announcement'">
            <!-- Short Description (v-text-field) -->
            <v-text-field v-model="shortDescription" :rules="[rules.required, rules.shortTextLimit]"
              label="Short Description" prepend-inner-icon="mdi-text" variant="underlined" color="primary" counter="150"
              @change="isShortDescriptionChanged = true" required></v-text-field>

            <!-- Full Description (v-textarea) -->
            <v-textarea v-model="textContent" label="Description" prepend-inner-icon="mdi-format-align-left"
              variant="underlined" color="primary" @change="isTextContentChange = true" rows="5" required></v-textarea>
          </template>

          <!-- Upload Section (Hidden for Event Category) -->
          <div v-if="category !== 'Event'" class="upload-section">
            <h4>Images and Files</h4>

            <!-- Title Image -->
            <v-file-input v-model="titleImage" label="Title Image" accept="image/png, image/jpeg"
              prepend-icon="mdi-image" variant="underlined" @update:modelValue="onTitleImageChange" color="primary">
              <template v-slot:selection="{ fileNames }">
                <template v-for="fileName in fileNames" :key="fileName">
                  <v-chip class="me-2" color="primary" size="small" label>
                    {{ fileName?.substring(0, 30) }}
                  </v-chip>
                </template>
              </template>
            </v-file-input>
            <p class="file-info-text">File format: .png, .jpg. Size: At maximum 1024 pixels in width (optimum: 750 x 498
              pixels).
            </p>

            <!-- Additional Images -->
            <v-file-input v-model="additionalImages" label="Additional Images" accept="image/png, image/jpeg"
              prepend-icon="mdi-folder-multiple-image" variant="underlined" color="primary"
              @update:modelValue="onAdditionalImagesChange" multiple counter show-size>
              <template v-slot:selection="{ fileNames }">
                <template v-for="fileName in fileNames" :key="fileName">
                  <v-chip class="me-2" color="primary" size="small" label>
                    {{ fileName?.substring(0, 30) }}
                  </v-chip>
                </template>
              </template>
            </v-file-input>

            <!-- Files (PDF only) -->
            <v-file-input v-model="files" label="Files (PDF only)" accept="application/pdf"
              prepend-icon="mdi-file-pdf-box" variant="underlined" color="primary" multiple counter
              @update:modelValue="onFilesChange" show-size>
              <template v-slot:selection="{ fileNames }">
                <template v-for="fileName in fileNames" :key="fileName">
                  <v-chip class="me-2" color="primary" size="small" label>
                    {{ fileName?.substring(0, 30) }}
                  </v-chip>
                </template>
              </template>
            </v-file-input>
            <p class="file-info-text">PDF only. Maximum 50 MB per file.</p>
          </div>

          <!-- Submit Button -->
          <v-btn v-if="!isEdit" @click="submitForm" color="primary" :disabled="!formValid" class="mt-4">Submit</v-btn>
          <v-btn v-else @click="editForm" color="primary" :disabled="!formValid || !isAnythingChanged"
            class="mt-4">Update</v-btn>
        </v-form>
      </v-container>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch, computed } from 'vue'
import Swal from 'sweetalert2'
import { getToolbarLogoImage } from '@/assets/Branding/branding'
import moment from 'moment'
import { showToast } from '@/services/utils'

import Loader from '@/components/general/Loader.vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import {
  createInfoItem,
  linkInfoItemToCategory,
  postFile,
  linkInfoItemTitleImage,
  postMultipleFiles,
  getFile,
  getInfoCategories,
  updateInfoItem,
  linkInfoTitleExtraImages,
  linkInfoItemAttachments,
  deleteInfoItemFiles,
  getFileInfo,
  removeStoredItem,
  deleteInfoItem,
  getInfoItemById,
  getInfoCategoryById,
} from '@/controllers/BaseController'

export default defineComponent({
  name: 'CreateEditInfoItem',
  components: {
    Loader,
  },
  setup() {
    const logo = ref('')
    const title = ref('')
    const route = useRoute()
    const router = useRouter()
    const category = ref('')
    const apiInfoCategories = ref([])
    const eventDateText = ref('')
    const eventDatePickerModel = ref(new Date())
    const shortDescription = ref('')
    const shortTextContent = ref('')
    const textContent = ref('')
    const titleImage = ref(null)
    const additionalImages = ref([])
    const files = ref([])
    const isTitleChanged = ref(false)
    const isEventDateChanged = ref(false)
    const isShortDescriptionChanged = ref(false)
    const isShortTextContentChanged = ref(false)
    const isTextContentChange = ref(false)
    const isTitleImageChanged = ref(false)
    const isAdditionalImagesChanged = ref(false)
    const isFilesChanged = ref(false)

    const categories = ref(['Event', 'News', 'Announcement'])
    const menu = ref(false)
    const showLoader = ref(false)
    const form = ref(null)
    const originalInfoItem = ref(null)

    const rules = {
      required: value => !!value || 'Required.',
      lineLimit: value => value.split('\n').length <= 1 || 'Only 1 line allowed.',
      titleLimit: value => value.length <= 100 || 'Maximum 100 characters allowed.',
      shortTextLimit: value => value.length <= 150 || 'Maximum 150 characters allowed.',
    }
    const onTitleImageChange = newValue => {
      isTitleImageChanged.value = true
      console.log('Title Image Changed:', newValue)
    }

    const onAdditionalImagesChange = newValue => {
      isAdditionalImagesChanged.value = true
      console.log('Additional Images Changed:', newValue)
    }

    const onFilesChange = newValue => {
      isFilesChanged.value = true
      console.log('Files Changed:', newValue)
    }
    const isAnythingChanged = computed(() => {
      return (
        isTitleChanged.value ||
        isEventDateChanged.value ||
        isShortDescriptionChanged.value ||
        isShortTextContentChanged.value ||
        isTextContentChange.value ||
        isTitleImageChanged.value ||
        isAdditionalImagesChanged.value ||
        isFilesChanged.value
      )
    })

    watch(category, newValue => {
      if (newValue && !isEdit.value) {
        title.value = ''
        eventDateText.value = ''
        eventDatePickerModel.value = new Date()
        shortTextContent.value = ''
        shortDescription.value = ''
        textContent.value = ''
        titleImage.value = null
        additionalImages.value = []
        files.value = []
      }
    })

    const formValid = computed(() => {
      return (
        title.value &&
        category.value &&
        (category.value !== 'Event' ? shortDescription.value : true) &&
        (category.value !== 'Event' ? textContent.value : shortTextContent.value) &&
        (category.value !== 'News' && category.value !== 'Announcement' ? true : titleImage.value) &&
        true // add any additional form validation checks here
      )
    })

    const isEdit = computed(() => {
      let params = JSON.parse(JSON.stringify(route.params))
      return params?.id
    })
    const isNew = computed(() => {
      let params = JSON.parse(JSON.stringify(route.params))
      return !params?.id
    })

    const updateEventDate = newDate => {
      eventDatePickerModel.value = newDate
      eventDateText.value = moment(newDate).format('DD-MM-YYYY')
      menu.value = false
    }

    const resizeImage = (file, maxWidth = 750, maxHeight = 498) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = () => {
          const canvas = document.createElement('canvas')
          let width = img.width
          let height = img.height

          if (width > maxWidth) {
            height *= maxWidth / width
            width = maxWidth
          }
          if (height > maxHeight) {
            width *= maxHeight / height
            height = maxHeight
          }

          canvas.width = width
          canvas.height = height

          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)

          canvas.toBlob(blob => {
            if (blob) {
              // Pass the original file name as well
              resolve(new File([blob], file.name, { type: file.type }))
            } else {
              reject(new Error('Image resize failed.'))
            }
          }, 'image/png')
        }
        img.onerror = () => reject(new Error('Image load failed.'))
      })
    }

    const editForm = async () => {
      if (!form.value || !(await form.value.validate())) return

      Swal.fire({
        title: 'Updating Info Item...',
        allowOutsideClick: false,
        didOpen: () => Swal.showLoading(),
      })

      let apiInfoItem = await getInfoItemById(route.params.id)

      try {
        const selectedInfoCategory = apiInfoCategories.value.find(item => item.name.toLowerCase().includes(category.value.toLowerCase()))

        const infoItemPayload = {
          title: title.value,
          active: true,
          version: apiInfoItem.version,
          shortText: category.value !== 'Event' ? shortDescription.value : eventDateText.value,
          text: category.value !== 'Event' ? textContent.value : shortTextContent.value,
          isNews: category.value === 'News',
          infoCategoryId: selectedInfoCategory?.id,
        }

        const infoItemId = route.params.id
        const infoItem = await updateInfoItem(infoItemId, infoItemPayload)

        showToast({ text: 'Info Item updated!', type: 'success' })

        // Handle Title Image
        if (isTitleImageChanged.value) {
          showToast({ text: 'Updating title image...', type: 'default' })
          const titleImageId = infoItem?.files?.entities.find(file => file.type === 'titleimage')?.id
          await deleteInfoItemFiles(infoItemId, [titleImageId])

          if (titleImage.value) {
            const titleImageBlob = await resizeImage(titleImage.value)
            const titleImageResponse = await postFile(titleImageBlob, 'image/png', titleImage.value.name) // Using original file name
            await linkInfoItemTitleImage(infoItemId, titleImageResponse.id)
            showToast({ text: 'Title image updated!', type: 'success' })
          }
        }

        // Handle Additional Images
        if (isAdditionalImagesChanged.value) {
          showToast({ text: 'Updating additional images...', type: 'default' })
          const additionalImagesFiles = infoItem?.files?.entities.filter(file => file.type === 'image')
          const additionalImagesFileIds = additionalImagesFiles.map(file => file.id)
          await deleteInfoItemFiles(infoItemId, additionalImagesFileIds)

          if (additionalImages.value.length > 0) {
            const additionalBlobs = await Promise.all(additionalImages.value.map(file => resizeImage(file)))
            const additionalImagesResponse = await postMultipleFiles(
              additionalBlobs,
              additionalImages.value.map(file => file.name)
            ) // Using original file names
            await linkInfoTitleExtraImages(
              infoItemId,
              additionalImagesResponse.map(file => file.id)
            )
            showToast({ text: 'Additional images updated!', type: 'success' })
          }
        }

        // Handle PDF Files
        if (isFilesChanged.value) {
          showToast({ text: 'Updating PDF files...', type: 'default' })
          const attachmentsFiles = infoItem?.files?.entities.filter(file => file.type === 'attachment')
          const attachmentsFileIds = attachmentsFiles.map(file => file.id)
          await deleteInfoItemFiles(infoItemId, attachmentsFileIds)

          if (files.value.length > 0) {
            const pdfResponse = await postMultipleFiles(files.value)
            await linkInfoItemAttachments(
              infoItemId,
              pdfResponse.map(file => file.id)
            )
            showToast({ text: 'PDF files updated!', type: 'success' })
          }
        }

        Swal.fire({
          title: 'Success!',
          text: 'Info Item updated successfully.',
          icon: 'success',
          confirmButtonColor: '#4caf50', // Light green
          confirmButtonText: 'OK',
        }).then(async () => {
          await removeStoredItem('infoItems')
          await removeStoredItem('infoCategories')
          router.push({ name: 'InfoCenterView' })
        })
      } catch (error) {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to update Info Item.',
          icon: 'error',
        })
      } finally {
        showLoader.value = false
      }
    }

    const submitForm = async () => {
      if (!form.value || !(await form.value.validate())) return

      Swal.fire({
        title: 'Creating Info Item...',
        allowOutsideClick: false,
        didOpen: () => Swal.showLoading(),
      })

      try {
        const selectedInfoCategory = apiInfoCategories.value.find(item => item.name.toLowerCase().includes(category.value.toLowerCase()))

        const infoItemPayload = {
          title: title.value,
          active: true,
          shortText: category.value !== 'Event' ? shortDescription.value : eventDateText.value,
          text: category.value !== 'Event' ? textContent.value : shortTextContent.value,
          isNews: category.value === 'News',
          infoCategoryId: selectedInfoCategory?.id,
        }

        const infoItem = await createInfoItem(infoItemPayload)
        const infoItemId = infoItem.id

        showToast({ text: 'Info Item created!', type: 'success' })

        await linkInfoItemToCategory(infoItemId, infoItemPayload.infoCategoryId)

        // Handle Title Image
        if (titleImage.value) {
          showToast({ text: 'Uploading title image...', type: 'default' })
          const titleImageBlob = await resizeImage(titleImage.value)
          const titleImageResponse = await postFile(titleImageBlob, 'image/png', titleImage.value.name) // Using original file name
          await linkInfoItemTitleImage(infoItemId, titleImageResponse.id)
          showToast({ text: 'Title image uploaded!', type: 'success' })
        }

        // Handle Additional Images
        if (additionalImages.value.length) {
          showToast({ text: 'Uploading additional images...', type: 'default' })
          const additionalBlobs = await Promise.all(additionalImages.value.map(file => resizeImage(file)))
          const additionalImagesResponse = await postMultipleFiles(
            additionalBlobs,
            additionalImages.value.map(file => file.name)
          ) // Using original file names
          await linkInfoTitleExtraImages(
            infoItemId,
            additionalImagesResponse.map(file => file.id)
          )
          showToast({ text: 'Additional images uploaded!', type: 'success' })
        }

        // Handle PDF Files
        if (files.value.length) {
          showToast({ text: 'Uploading PDF files...', type: 'default' })
          const pdfResponse = await postMultipleFiles(files.value)
          await linkInfoItemAttachments(
            infoItemId,
            pdfResponse.map(file => file.id)
          )
          showToast({ text: 'PDF files uploaded!', type: 'success' })
        }

        Swal.fire({
          title: 'Success!',
          text: 'Info Item created successfully.',
          icon: 'success',
          confirmButtonColor: '#4caf50', // Light green
          confirmButtonText: 'OK',
        }).then(async () => {
          await removeStoredItem('infoItems')
          await removeStoredItem('infoCategories')
          router.push({ name: 'InfoCenterView' })
        })
      } catch (error) {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to create Info Item.',
          icon: 'error',
        })
      } finally {
        showLoader.value = false
      }
    }

    onMounted(async () => {
      try {
        logo.value = await getToolbarLogoImage()
        category.value = 'Event'
        let API_CATEGORIES = await getInfoCategories()
        API_CATEGORIES = API_CATEGORIES.filter(
          category => category.name === 'Events' || category.name === 'News' || category.name === 'Announcement'
        )
        apiInfoCategories.value = API_CATEGORIES
        if (isEdit.value) {
          let { id } = route.params
          let infoItem = await getInfoItemById(id)
          let infoCategory = await getInfoCategoryById(infoItem.infoCategory?.id)

          if (infoCategory?.name?.toLowerCase() === 'events') {
            category.value = 'Event'
            title.value = infoItem.title
            eventDateText.value = moment(infoItem.shortText, 'DD-MM-YYYY').format('DD-MM-YYYY')
            eventDatePickerModel.value = moment(infoItem.shortText, 'DD-MM-YYYY').toDate()
            shortTextContent.value = infoItem.text
          } else {
            category.value = infoCategory?.name
            title.value = infoItem.title
            shortDescription.value = infoItem.shortText
            textContent.value = infoItem.text.replace(/<p>/g, '').replace(/<\/p>/g, '')

            // Fetch and set the title image
            let titleImageFile = infoItem?.files?.entities.find(file => file.type === 'titleimage')
            if (titleImageFile) {
              const fileBlob = await getFile(titleImageFile.id) // Get the actual file (blob)
              const fileInfo = await getFileInfo(titleImageFile.id) // Get the file info (fileName, mimeType, url)
              console.log(fileInfo)
              titleImage.value = new File([fileBlob], fileInfo.fileName, { type: fileInfo.mimeType })
            }

            // Fetch and set additional images
            let additionalImagesFiles = infoItem?.files?.entities.filter(file => file.type === 'image')
            if (additionalImagesFiles.length > 0) {
              additionalImages.value = await Promise.all(
                additionalImagesFiles.map(async file => {
                  const fileBlob = await getFile(file.id) // Get the actual file (blob)
                  const fileInfo = await getFileInfo(file.id) // Get the file info (fileName, mimeType, url)
                  return new File([fileBlob], fileInfo.fileName, { type: fileInfo.mimeType })
                })
              )
            }

            // Fetch and set attachments (PDFs)
            let attachmentsFiles = infoItem?.files?.entities.filter(file => file.type === 'attachment')
            if (attachmentsFiles.length > 0) {
              files.value = await Promise.all(
                attachmentsFiles.map(async file => {
                  const fileBlob = await getFile(file.id) // Get the actual file (blob)
                  const fileInfo = await getFileInfo(file.id) // Get the file info (fileName, mimeType, url)
                  return new File([fileBlob], fileInfo.fileName, { type: fileInfo.mimeType })
                })
              )
            }
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        showLoader.value = false
      }
    })

    return {
      logo,
      title,
      category,
      eventDateText,
      eventDatePickerModel,
      shortDescription,
      shortTextContent,
      textContent,
      titleImage,
      additionalImages,
      files,
      formValid,
      categories,
      menu,
      showLoader,
      rules,
      updateEventDate,
      submitForm,
      resizeImage,
      route,
      form,
      isEdit,
      isNew,
      isTitleChanged,
      isEventDateChanged,
      isShortDescriptionChanged,
      isShortTextContentChanged,
      isAdditionalImagesChanged,
      isFilesChanged,
      isTextContentChange,
      editForm,
      onTitleImageChange,
      onAdditionalImagesChange,
      onFilesChange,
      apiInfoCategories,
      isAnythingChanged,
      router,
    }
  },
})
</script>

<style scoped>
.create-edit-infoitem {
  padding: 1rem;
}

::v-deep .v-date-picker .mdi-chevron-right {
  margin-left: -2rem;
  /* Add any other style customizations here */
}

/* Example of more targeted customization, if necessary */
::v-deep .v-date-picker .v-icon.mdi-chevron-right {
  margin-left: -2rem;
}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 120px);
}

.toolbar-icon {
  height: 30px;
  align-self: center;
}

.create-edit-info-item {
  max-width: 900px;
  margin: 2rem auto;
  background: white;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

.upload-section {
  margin: 2rem 0;
}

.file-info-text {
  font-size: 0.85rem;
  color: #666;
  margin-top: -12px;
  margin-bottom: 24px;
}

h4 {
  font-weight: bold;
  color: #007bff;
  margin-bottom: 1rem;
}

.v-text-field,
.v-textarea,
.v-file-input,
.v-select,
.v-html-editor {
  margin-bottom: 1.5rem;
}

/* Added styles to make the chips more visible */
.v-chip {
  background-color: #e0f7fa !important;
  color: #007bff !important;
  margin: 4px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .create-edit-info-item {
    padding: 1.5rem;
    max-width: 100%;
  }

  .custom-toolbar-title {
    font-size: 1.4rem;
  }
}

@media (max-width: 640px) {
  .custom-toolbar-title {
    font-size: large;
  }
}
</style>
