<template>
  <div v-if="showBottomNav" :class="['dock', isIosDevice ? 'ios-dock' : '']">
    <div
      v-for="(option, index) in options"
      :key="index"
      :class="['dock-item', localSelected === index ? 'active' : '', isIosDevice ? 'ios-dock-item' : '']"
      @click="setSelected(index, option.path)"
      @mouseover="hoverEffect($event, true)"
      @mouseleave="hoverEffect($event, false)"
    >
      <v-icon size="30" v-if="isIosDevice">{{ option.icon }}</v-icon>
      <v-icon v-else>{{ option.icon }}</v-icon>

      <span class="dock-title">{{ option.title }}</span>
    </div>
  </div>
</template>
<script>
  import { ref, computed, onMounted, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { VIcon } from 'vuetify/lib/components/VIcon'
  import { isIos } from '@/services/OAuthService'

  export default {
    components: {
      VIcon,
    },
    props: {
      options: {
        type: Array,
        default: () => [
          {
            id: 1,
            icon: 'mdi-newspaper-variant-outline',
            title: 'News',
            path: { name: 'InfoCenterView' },
          },
          {
            id: 2,
            icon: 'mdi-office-building-marker-outline',
            title: 'Space',
            path: { name: 'BuildingView' },
          },
          {
            id: 3,
            icon: 'mdi-calendar-month-outline',
            title: 'Booking',
            path: { name: 'BookingPoisList' },
          },
          {
            id: 4,
            icon: 'mdi-chat-outline',
            title: 'Chat',
            path: { name: 'ChatUsersList' },
          },
          // {
          //   id: 5,
          //   icon: 'mdi-bullhorn-outline',
          //   title: 'Info',
          //   path: { name: 'MarketingView' },
          // },
          {
            id: 6,
            icon: 'mdi-menu-open',
            title: 'Menu',
            path: { name: 'MenuView' },
          },
        ],
      },
      selected: {
        type: Number,
        default: 4,
      },
    },
    setup(props, { emit }) {
      const route = useRoute()
      const router = useRouter()
      const showBottomNav = computed(() => route.name !== 'Login' && !window.location.href.includes('/oauth2'))
      const localSelected = ref(props.selected)
      const isIosDevice = ref(false)
      onMounted(async () => {
        isIosDevice.value = await isIos()
      })

      const setSelected = (index, path) => {
        localSelected.value = index
        router.push(path)
      }

      const hoverEffect = (event, isHover) => {
        const item = event.currentTarget
        item.style.transform = isHover ? 'scale(1.15)' : 'scale(1)'
      }

      watch(
        () => route.path,
        newPath => {
          const foundIndex = props.options.findIndex(option => router.resolve(option.path).href === newPath)
          if (foundIndex !== -1) {
            localSelected.value = foundIndex
          }
        },
        { immediate: true }
      )
      watch(
        () => route.path,
        newPath => {
          if (newPath)
            if (newPath.includes('/infocenter')) {
              localSelected.value = 0
            }
          if (newPath.includes('/building')) {
            localSelected.value = 1
          }
          if (newPath.includes('/booking')) {
            localSelected.value = 2
          }
          if (newPath.includes('/chat')) {
            localSelected.value = 3
          }
          // if (newPath.includes('/marketing')) {
          //   localSelected.value = 4
          // }
          if (newPath.includes('/menu')) {
            localSelected.value = 4
          }
        },
        { immediate: true }
      )

      return {
        showBottomNav,
        localSelected,
        setSelected,
        hoverEffect,
        isIosDevice,
      }
    },
  }
</script>

<style scoped>
  .dock {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    position: fixed;
    bottom: 0;
    gap: 15px;
    left: 0;
    right: 0;
    z-index: 100;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }

  /* iOS specific styling for the dock */
  .ios-dock {
    padding: 20px 20px;
    /* Increase padding to lift the navbar slightly */
    height: 100px;
    /* Increased height for the iOS bottom nav */
  }

  /* Regular dock item styles */
  .dock-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    border-radius: 14px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    cursor: pointer;
  }

  /* iOS specific styling for dock items */
  .ios-dock-item {
    padding: 10px 14px;
    /* Increase padding for iOS items */
    transform: translateY(-5px);
    /* Lift the icons a bit */
  }

  .dock-item:hover {
    transform: scale(1.2);
    background-color: rgba(67, 167, 249, 0.15);
    box-shadow: 0 4px 10px rgba(67, 167, 249, 0.3);
  }

  .active {
    background-color: rgba(67, 167, 249, 0.3);
    box-shadow: 0 10px 25px rgba(67, 167, 249, 0.5);
    transform: scale(1.2);
  }

  .v-icon {
    font-size: 28px;
    color: #555;
    transition: color 0.3s ease;
  }

  .dock-title {
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #555;
    transition: color 0.3s ease;
  }

  .dock-item.active .v-icon,
  .dock-item.active .dock-title {
    color: #43a7f9;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .dock-item {
      padding: 6px 8px;
    }

    /* iOS specific responsive changes */
    .ios-dock-item {
      padding: 8px 10px;
    }

    .dock-title {
      font-size: 10px;
    }

    .v-icon {
      font-size: 24px;
    }
  }

  /* Subtle gradient animation */
  @keyframes gradient {
    0% {
      background-color: rgba(67, 167, 249, 0.1);
    }

    50% {
      background-color: rgba(67, 167, 249, 0.3);
    }

    100% {
      background-color: rgba(67, 167, 249, 0.1);
    }
  }

  .dock-item.active {
    animation: gradient 2s ease infinite;
  }
</style>
