<template>
  <v-container class="analytics" fluid>
    <Loader :loading="showLoader" />
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <v-btn icon>
        <v-icon size="40" @click="goBack">
          mdi-arrow-left-bold-circle
        </v-icon>
      </v-btn>
      <v-toolbar-title class="custom-toolbar-title">
        <!-- Align "Functions" title next to the logo -->
        {{ 'Analytics' }}
      </v-toolbar-title>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>

    <v-container fluid class="py-6">
      <v-row justify="center">
        <v-col cols="12" md="6" lg="3" v-for="analytic in analytics" :key="analytic.id">
          <v-card class="mx-auto my-4" :hover="true" :elevation="3" outlined @click="goToAnalytics(analytic)">
            <div class="card-image">
              <img :src="analytic.image" alt="Card image" />
            </div>
            <v-card-title>{{ analytic.title }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { ref, defineComponent, onMounted } from 'vue'
import Loader from '@/components/general/Loader.vue'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'AnalyticsList',
  components: {
    Loader,
  },
  setup() {
    const logo = ref('')
    const showLoader = ref(false)
    const router = useRouter()
    const analytics = ref([
      {
        id: 1,
        title: 'Energy Consumption',
        image: require('@/assets/General/energy_graph_animated.gif'),
      },
      {
        id: 2,
        title: 'Office Days',
        image: require('@/assets/General/performance_chart.jpg'),
      },
      {
        id: 3,
        title: 'App Usage',
        image: require('@/assets/General/app_usage_chart.jpg'),
      },
      {
        id: 4,
        title: 'Occupancy',
        image: require('@/assets/General/energy_graph.png'),
      },
    ])

    onMounted(async () => {
      try {
        logo.value = await getToolbarLogoImage()
        showLoader.value = true
      } catch (error) {
        console.error(error)
      } finally {
        showLoader.value = false
      }
    })

    const goToAnalytics = item => {
      let selectedItem = JSON.parse(JSON.stringify(item))
      selectedItem = selectedItem?.title?.toLowerCase()
      if (selectedItem?.includes('occupancy')) {
        router.push({ name: 'OccupancyAnalytics' }).catch(err => console.error(err))
      } else if (selectedItem?.includes('office')) {
        router.push({ name: 'OfficeAnalytics' }).catch(err => console.error(err))
      }
    }

    const goBack = () => {
      window.history.back()
    }

    return {
      logo,
      showLoader,
      analytics,
      goToAnalytics,
      goBack,
    }
  },
})
</script>

<style scoped>
.analytics {
  padding: 1rem;
}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333;
  margin-left: 10px;
  /* Spacing between logo and title */
}

.toolbar-icon {
  height: 30px;
}

.v-card {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.v-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.v-card-title {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding: 16px 0;
}

.card-image {
  height: 200px;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
