<template>
  <div class="wrap">
    <button @click="goToApp()" class="glow-on-hover" type="button">Jump in App!</button>
  </div>
</template>

<script>
  export default {
    name: 'GlowingButton',
    methods: {
      goToApp() {
        this.$router.push({ name: 'InfoCenterView' })
      },
    },
  }
</script>

<style scoped>
  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  /* Base button styling with a whitish color */
  .glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #313133;
    background: #f5f5f5;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  }

  /* Glowing animation on the border */
  .glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    border-radius: 10px;
  }

  .glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  /* Subtle hover effect */
  .glow-on-hover:hover {
    background: #e0e0e0; /* Slightly darker background on hover */
    transform: translateY(-4px); /* Hover lift effect */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
  }

  /* Glowing animation keyframes */
  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .glow-on-hover {
      width: 180px;
      height: 45px;
      font-size: 16px;
    }
  }

  @media (max-width: 480px) {
    .glow-on-hover {
      width: 150px;
      height: 40px;
      font-size: 14px;
    }
  }
</style>
