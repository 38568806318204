<template>
  <div class="booking-management">
    <!-- Loader and Toolbar -->
    <Loader :loading="showLoader" />
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <v-btn icon>
        <v-icon size="40" @click="goBack">
          mdi-arrow-left-bold-circle
        </v-icon>
      </v-btn>
      <v-toolbar-title class="custom-toolbar-title">
        <!-- Align "Functions" title next to the logo -->
        {{ 'Admin Bookings Management' }}
      </v-toolbar-title>
      <img :src="logo" class="toolbar-icon" v-if="isLargeScreen || isMediumScreen" />
    </v-toolbar>

    <!-- Filters Section -->
    <v-container class="mt-2 mb-0" style="background-color: transparent; box-shadow: none">
      <v-card-text>
        <v-row align="center" class="mb-2">
          <v-col cols="auto">
            <v-btn icon @click="menu = !menu" color="primary" style="margin-top: -1rem">
              <v-icon>{{ menu ? 'mdi-menu-open' : 'mdi-menu' }}</v-icon>
            </v-btn>
          </v-col>

          <v-col>
            <v-text-field v-model="searchQuery" label="Search" variant="outlined" prepend-inner-icon="mdi-magnify" dense
              class="mx-4" style="margin-bottom: 0"></v-text-field>
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-row dense v-if="menu" class="mt-2">
            <v-col cols="12" sm="6" md="3">
              <v-select v-model="selectedDateRange" :items="dateOptions" label="Date Range" solo dense
                prepend-inner-icon="mdi-calendar-range" @update:modelValue="onDateRangeChange"></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-select v-model="selectedCategory" :items="categories" item-value="name" item-title="name"
                label="Categories" solo return-object dense prepend-inner-icon="mdi-filter-variant"
                @update:modelValue="categoryChanged"></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-select v-model="selectedFloor" :items="floors" label="Floors" solo item-value="name" item-title="name"
                return-object dense prepend-inner-icon="mdi-elevator" @update:modelValue="floorChanged"></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-select v-model="selectedPoi" :items="pois" label="Rooms/Desks" solo item-value="name" item-title="name"
                dense return-object prepend-inner-icon="mdi-door" @update:modelValue="poiChanged"></v-select>
            </v-col>
          </v-row>
        </v-expand-transition>

        <v-row align="center" justify="center" class="mt-2 mb-2">
          <v-col cols="auto" class="d-flex justify-center">
            <v-btn color="light-green" outlined @click="fetchPoisList">
              <v-icon left>mdi-magnify</v-icon>
              Show Bookings
            </v-btn>
          </v-col>
          <v-col cols="auto" class="d-flex justify-center">
            <v-chip label>{{ 'Total: ' + reservations.length }}</v-chip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>

    <!-- Table Section -->
    <v-container v-if="isLargeScreen || isMediumScreen" fluid style="padding: 0 16px; margin-bottom: 0.5rem">
      <v-table :hover="true" density="comfortable" class="elegant-table mt-4" v-if="reservations.length">
        <thead>
          <tr>
            <th class="text-center">Name</th>
            <th class="text-center">Email</th>
            <th class="text-center">Floor</th>
            <th class="text-center">Room</th>
            <th class="text-center">Date</th>
            <th class="text-center">Time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="reservation in reservations" :key="reservation.id" style="cursor: pointer">
            <td>{{ reservation.creatorName }}</td>
            <td>{{ reservation.creatorEmail }}</td>
            <td>{{ reservation.floorName }}</td>
            <td>{{ reservation.poiName }}</td>
            <td>{{ formatDate(reservation.startTime) }}</td>
            <td>{{ formatTime(reservation.startTime, reservation.endTime) }}</td>
          </tr>
        </tbody>
      </v-table>
      <p v-if="!reservations.length" class="text-center">No reservations found.</p>
    </v-container>

    <!-- Small Screen Card Layout -->
    <v-container v-else>
      <div v-for="(reservation, index) in reservations" :key="index">
        <v-card class="booking-list" elevation="3" outlined tile
          style="margin-top: 0.8rem; margin-bottom: 1rem; border: inset" max-width="100%"
          @click="handleClick(reservation)">
          <v-card-title class="grey lighten-2"
            :style="{ padding: '8px 16px', margin: index !== 0 ? '0px 0px 0px;' : '0', borderBottom: '1px solid #E0E0E0' }">
            <v-row align="center" justify="space-between" class="ma-0">
              <v-col cols="auto" class="pa-0 ma-0">
                <v-btn :class="{
                  'very-small-btn': isVerySmallScreen,
                  'small-btn': !isVerySmallScreen,
                }" depressed color="#992727" class="mr-2 white--text">
                  {{ moment(reservation.startTime).format(windowWidth < 440 ? 'ddd' : 'dddd') }} </v-btn>
                    <v-btn :class="{
                      'very-small-btn': isVerySmallScreen,
                      'small-btn': !isVerySmallScreen,
                    }" depressed color="#992727" class="white--text">
                      {{ moment(reservation.startTime).format(windowWidth < 440 ? 'DD.MM.YY' : 'DD.MM.YYYY') }}
                        </v-btn>
              </v-col>
              <v-col class="pa-0 ma-0 d-flex justify-end flex-grow-1">
                <v-btn :class="{
                  'very-small-btn': isVerySmallScreen,
                  'small-btn': !isVerySmallScreen,
                }" depressed class="white--text mr-2" color="#414a4c" style="color: white; width: 110px">
                  {{ moment(reservation.startTime).format('HH:mm') + ' - ' + moment(reservation.endTime).format('HH:mm')
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text style="margin-bottom: -1rem; margin-top: 0.2rem">
            <div class="d-flex align-center">
              <v-icon size="small" class="mr-2">mdi-account</v-icon>
              <span>{{ reservation.creatorName }}</span>
            </div>
            <div class="d-flex align-center">
              <v-icon size="small" class="mr-2">mdi-email</v-icon>
              <span>{{ reservation.creatorEmail }}</span>
            </div>
            <div class="d-flex align-center">
              <v-icon size="small" class="mr-2">mdi-seat</v-icon>
              <span>{{ reservation.poiName }}</span>
            </div>
            <div class="d-flex align-center">
              <v-icon size="small" class="mr-2">mdi-stairs</v-icon>
              <span>{{ reservation.floorName }}</span>
            </div>
            <div class="d-flex align-center">
              <v-icon size="small" class="mr-2">mdi-door-closed</v-icon>
              <span>{{ reservation.poiCategory }}</span>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-container>

    <!-- Date Range Dialog -->
    <v-dialog v-model="dateRangeDialog" persistent max-width="300px">
      <v-card>
        <v-card-title>Custom Date Range</v-card-title>
        <v-card-text>
          <v-date-picker v-model="dates" color="primary" range></v-date-picker>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="red" text @click="discardRange">Cancel</v-btn>
          <v-btn color="green" text @click="confirmRange">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, reactive, onMounted, computed, onUnmounted, defineComponent } from 'vue'
import { debounce } from 'lodash'
import Loader from '@/components/general/Loader.vue'
import moment from 'moment'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import lodash from 'lodash'
import { useRouter } from 'vue-router'
import {
  isStaingOrQa,
  getPoisByCategoryId,
  getPoiCategories,
  getReservationsByPoi,
  getFloorById,
  getAppointmentById,
} from '@/controllers/BaseController'

export default defineComponent({
  name: 'BookingsManagement',
  components: {
    Loader,
  },
  setup() {
    const showLoader = ref(true)
    const router = useRouter()
    const menu = ref(false)
    const searchQuery = ref('')
    const selectedDateRange = ref('')
    const dateRangeDialog = ref(false)
    const logo = ref('')
    const originalPois = ref([])
    const originalFloors = ref([])
    const originalCategories = ref([])
    const windowWidth = ref(window.innerWidth)
    const isLargeScreen = computed(() => windowWidth.value > 1000)
    const isMediumScreen = computed(() => windowWidth.value <= 1000 && windowWidth.value >= 800)
    const isSmallScreen = computed(() => windowWidth.value < 800)
    const isVerySmallScreen = computed(() => windowWidth.value < 375)

    const dates = reactive({
      start: new Date(),
      end: null,
    })

    const selectedCategory = ref(null)
    const selectedFloor = ref(null)
    const selectedPoi = ref(null)

    const reservations = ref([])
    const categories = ref([])
    const floors = ref([])
    const pois = ref([])

    const dateOptions = ['Today', 'This Week', 'This Month', 'Custom']

    const fetchDummyPoisList = async () => {
      try {
        showLoader.value = true
        let reservationsData = await import('@/dummydata/reservations.json')
        reservationsData = reservationsData.reservations
        let floor = JSON.parse(JSON.stringify(selectedFloor.value))
        let selectedPois = JSON.parse(JSON.stringify(selectedCategory.value.pois))
        let poi = JSON.parse(JSON.stringify(selectedPoi.value))
        const fromTime = moment(dates.start)
        const untilTime = moment(dates.end)
        let filteredReservations = []

        selectedPois.forEach(poi => {
          filteredReservations = lodash.filter(
            reservationsData,
            reservation =>
              reservation.poiId === poi.id &&
              moment(reservation.startTime).isSameOrAfter(fromTime) &&
              moment(reservation.endTime).isSameOrBefore(untilTime)
          )
        })
        if (floor.id !== 0) {
          filteredReservations = lodash.filter(filteredReservations, reservation => reservation.floorName === floor.name)
        }
        if (poi.id !== 0) {
          filteredReservations = lodash.filter(filteredReservations, reservation => reservation.poiId === poi.id)
        }
        reservations.value = filteredReservations
        reservations.value = lodash.orderBy(reservations.value, [r => moment(r.startTime)], ['asc'])
      } catch (error) {
        console.log(error)
      } finally {
        showLoader.value = false
      }
    }

    const fetchPoisList = async () => {
      try {
        let isPia = await isStaingOrQa()
        if (isPia) {
          return fetchDummyPoisList()
        } else {
          showLoader.value = true

          const startDate = dates.start
          const endDate = dates.end
          const categoryPoiIds = selectedCategory.value.pois.map(poi => poi.id)

          let allReservations = []
          for (const id of categoryPoiIds) {
            const bookings = await getReservationsByPoi(id, new Date(startDate), new Date(endDate))
            console.log(bookings)
            for (const booking of bookings) {
              const appointment = await getAppointmentById(booking.appointment?.id)

              booking.floor = lodash.find(floors.value, floor => floor?.name === booking.floorName)
              if (!booking.floor) continue

              booking.creatorName = booking.creatorName || appointment?.organizerName || booking?.creatorEmail
              booking.attendees = appointment?.invitees?.length

              if (booking.poiName) {
                booking.poiName = booking.poiName.replace(/\s*{[^}]*}\s*/g, '').trim()
              }

              allReservations.push(booking)
            }
          }

          const reservationMap = {}
          for (const reservation of allReservations) {
            if (reservation.recurringId) {
              if (!reservationMap[reservation.recurringId]) {
                reservationMap[reservation.recurringId] = { ...reservation, count: [reservation] }
              } else {
                reservationMap[reservation.recurringId].count.push(reservation)
              }
            } else {
              reservationMap[reservation.id] = reservation
            }
          }

          reservations.value = lodash.orderBy(Object.values(reservationMap), [r => moment(r.startTime)], ['asc'])

          if (selectedFloor.value?.id !== 0) {
            reservations.value = lodash.filter(reservations.value, reservation => reservation.floor?.id === selectedFloor.value.id)
          }
          if (selectedPoi.value?.id !== 0) {
            reservations.value = lodash.filter(reservations.value, reservation => reservation.poiId === selectedPoi.value.id)
          }
        }
      } catch (error) {
        console.error(error)
        showLoader.value = false
      } finally {
        showLoader.value = false
      }
    }

    const categoryChanged = async () => {
      let categoriesCopy = JSON.parse(JSON.stringify(originalCategories.value))
      let categoryWithPois = lodash.find(categoriesCopy, category => category.id === selectedCategory.value.id)
      const poisResult = categoryWithPois['pois']
      pois.value = poisResult
      floors.value = lodash.filter(originalFloors.value, floor => floor?.pois?.ids?.some(id => poisResult.map(poi => poi.id).includes(id)))
      floors.value = [{ id: 0, name: 'All Floors' }, ...lodash.sortBy(floors.value, 'name')]
      const defaultPoi = { id: 0, name: 'All Rooms/Desks' }
      pois.value = [defaultPoi, ...poisResult]
      selectedPoi.value = defaultPoi
      selectedFloor.value = floors.value[0]
    }

    const floorChanged = () => {
      if (selectedFloor.value?.id !== 0) {
        pois.value = lodash.filter(
          originalPois.value,
          poi => poi?.floors?.ids[0] === selectedFloor.value.id && poi?.poiCategories.ids[0] === selectedCategory.value.id
        )
        const defaultPoi = { id: 0, name: 'All Rooms/Desks' }
        pois.value = [defaultPoi, ...pois.value]
        selectedPoi.value = defaultPoi
      } else {
        pois.value = selectedCategory.value.pois
        const defaultPoi = { id: 0, name: 'All Rooms/Desks' }
        pois.value = [defaultPoi, ...pois.value]
        selectedPoi.value = defaultPoi
      }
    }

    const poiChanged = () => {
      selectedFloor.value = lodash.find(floors.value, { id: selectedPoi.value.floors.ids[0] })
    }

    const onDateRangeChange = item => {
      if (item) {
        selectedDateRange.value = item
      }
      switch (selectedDateRange.value) {
        case 'Today':
          dates.start = moment().startOf('day').toDate()
          dates.end = moment().endOf('day').toDate()
          break
        case 'This Week':
          dates.start = moment().startOf('week').toDate()
          dates.end = moment().endOf('week').toDate()
          break
        case 'This Month':
          dates.start = moment().startOf('month').toDate()
          dates.end = moment().endOf('month').toDate()
          break
        case 'Custom':
          dateRangeDialog.value = true
          break
        default:
          dateRangeDialog.value = false
          break
      }
    }

    const confirmRange = () => {
      dates.start = moment(dates.start).hour(6).minute(0).toDate()
      dates.end = moment(dates.end).hour(20).minute(0).toDate()
      dateRangeDialog.value = false
      fetchPoisList()
    }

    const discardRange = () => {
      dateRangeDialog.value = false
    }

    const goBack = () => {
      window.history.back()
    }

    const formatDate = (date, format = 'DD.MM.YYYY') => moment(date).format(format)
    const formatTime = (startTime, endTime) => `${moment(startTime).format('HH:mm')} - ${moment(endTime).format('HH:mm')}`

    onMounted(async () => {
      const resizeListener = debounce(() => (windowWidth.value = window.innerWidth), 200)
      window.addEventListener('resize', resizeListener)
      let isPia = await isStaingOrQa()

      try {
        showLoader.value = true

        const [logoImage, categoriesResult] = await Promise.all([getToolbarLogoImage(), getPoiCategories()])

        logo.value = logoImage

        const validCategories = categoriesResult.filter(item => item.pois?.ids?.length !== 0)

        const poisResult = await Promise.all(
          validCategories.map(async category => {
            const pois = await getPoisByCategoryId(category.id)
            return { ...category, pois }
          })
        )

        const allPois = poisResult.flatMap(category => category.pois)

        const floorsResult = await Promise.all([...new Set(allPois.map(poi => poi.floors.ids[0]))].map(floorId => getFloorById(floorId)))

        categories.value = lodash.sortBy(poisResult, 'name')
        pois.value = [{ id: 0, name: 'All Rooms/Desks' }, ...lodash.sortBy(allPois, 'name')]
        floors.value = [{ id: 0, name: 'All Floors' }, ...lodash.sortBy(floorsResult, 'name')]
        originalPois.value = pois.value
        originalFloors.value = floors.value
        originalCategories.value = categories.value

        selectedCategory.value = isPia ? lodash.find(categories.value, category => category.name === 'LAB') : categories.value[0]
        selectedFloor.value = floors.value[0]
        selectedPoi.value = pois.value[0]
        selectedDateRange.value = dateOptions[0]
        onDateRangeChange('This Month')
        fetchPoisList()
      } catch (error) {
        console.error('Failed to load data:', error)
        showLoader.value = false
      } finally {
        onUnmounted(() => window.removeEventListener('resize', resizeListener))
      }
    })

    return {
      showLoader,
      menu,
      searchQuery,
      selectedDateRange,
      dateRangeDialog,
      logo,
      originalPois,
      originalFloors,
      originalCategories,
      dates,
      selectedCategory,
      selectedFloor,
      selectedPoi,
      reservations,
      categories,
      router,
      floors,
      pois,
      windowWidth,
      dateOptions,
      fetchPoisList,
      categoryChanged,
      floorChanged,
      poiChanged,
      onDateRangeChange,
      confirmRange,
      discardRange,
      formatDate,
      formatTime,
      goBack,
      isLargeScreen,
      isMediumScreen,
      isSmallScreen,
      isVerySmallScreen,
      moment,
    }
  },
})
</script>

<style scoped>
.booking-management {
  padding: 1rem;
}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333;
  margin-left: 10px;
  /* Spacing between logo and title */
}

.toolbar-icon {
  height: 30px;
}

.elegant-table {
  border-collapse: collapse;
  width: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.elegant-table thead {
  background-color: #4caf50;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.95rem;
  text-align: center;
}

.elegant-table th,
.elegant-table td {
  padding: 12px 16px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.elegant-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.elegant-table tbody tr:hover {
  background-color: #f1f1f1;
}

.booking-list {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 8px 0;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .elegant-table thead {
    display: none;
  }

  .elegant-table tbody tr {
    display: block;
    margin-bottom: 16px;
    padding: 12px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .elegant-table tbody td {
    display: block;
    text-align: left;
    padding: 8px 0;
    border: none;
  }

  .elegant-table tbody td:before {
    content: attr(data-label);
    font-weight: bold;
    display: inline-block;
    margin-right: 8px;
    color: #444;
  }

  .small-btn {
    font-size: 0.75rem;
    /* Adjust as needed */
    padding: 6px 12px;
    min-width: 64px;
  }

  /* Button styles for very small screens */
  .very-small-btn {
    font-size: 0.8rem;
    /* Smaller font size */
    padding: 4px 8px;
    /* Smaller padding */
    min-width: 50px;
    /* Smaller minimum width */
  }
}
</style>
